@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Poppins", sans-serif;
  }
}

.hover-bg-effect {
  position: relative;
  color: transparent; /* Set the color of the actual text to transparent so that only the pseudo-element's text is visible */
}

.hover-bg-effect::before {
  content: attr(data-text); /* Use data attributes to display the text */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2; /* This will always be on top */
  color: white;
}

.hover-bg-effect::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0582ca;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.3s;
  z-index: 1; /* Below the text but above the link's actual background */
}

.hover-bg-effect:hover::after {
  transform: scaleY(1);
}

@keyframes code-writing {
  0% {
    stroke-dasharray: 0, 100;
  }
  100% {
    stroke-dasharray: 100, 100;
  }
}

.code-text {
  stroke-dasharray: 100, 100;
  stroke-dashoffset: 100;
  animation: code-writing 3s forwards;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-in-from-right {
  animation: slideInFromRight 4s forwards;
}

/* for About */

.animate-fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 5s;
}

/* for sidebar */

.left-wrapper {
  width: 200px;
}

/* Mobile and smaller screens */
@media (max-width: 768px) {
  .hamburger-menu {
    z-index: 30; /* Ensure the button is above other content */
  }

  /* Adjust breakpoint as needed */
  .left-wrapper {
    display: none; /* Hide by default */
    width: 100%; /* Take full width when displayed */
  }

  .show-sidebar {
    display: block; /* Show when the toggle button is clicked */
  }

  .main-content {
    margin-left: 0; /* No left margin on smaller screens */
  }
}

#message::placeholder {
  color: white;
}

#subject::placeholder {
  color: white;
}
#contact-email::placeholder {
  color: white;
}

#name::placeholder {
  color: white;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 2rem; /* Width of the hamburger menu */
    height: 1.5rem; /* Total height of the hamburger menu */
    cursor: pointer;
    z-index: 10;
    position: absolute;
    top: 1rem; /* Adjust as needed */
    left: 1rem; /* Adjust as needed */
  }

  body {
    padding-top: 32px; /* Adjust this value based on the actual height of your NavBar */
  }

  .hamburger-line {
    height: 0.25rem; /* Height of one line of the hamburger menu */
    background-color: #fff; /* Color of the hamburger lines */
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }

  /* This will be the sidebar that slides in from the top */
  .sidebar-content {
    position: fixed; /* Fixed position to stay in place during scroll */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0582ca; /* Replace with your sidebar background color */
    overflow-y: auto; /* Allows scrolling if content is taller than the screen */
    transition: transform 0.3s ease-in-out; /* Smooth transition for sliding effect */
    z-index: 5; /* Ensure it's above other content, but below the hamburger menu */
  }

  button {
    background: none; /* Remove any default background */
    border: none; /* Remove any default border */
    padding: 0; /* Remove any default padding */
    margin: 0; /* Remove any default margin */
    cursor: pointer; /* Change cursor to pointer on hover */
  }
}

.shadow-photo {
  box-shadow: 0 20px 50px #1a3a4f;
}

.bg-custom-blue-hover {
  background-color: #0582ca; /* Replace with your actual color */
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.active\:scale-95:active {
  transform: scale(0.95);
}

.button-beautiful {
  background-image: linear-gradient(to right, #007cf0, #00dfd8);
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 4px 14px 0 rgba(0, 118, 255, 0.39);
  transition: transform 0.2s, box-shadow 0.2s;
}

.button-beautiful:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 118, 255, 0.23);
}

@keyframes buttonClickAnimation {
  0% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-2px);
  }
}

.button-clicked {
  animation: buttonClickAnimation 0.4s ease;
}

@media screen and (max-width: 768px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .page-content {
    transition: transform 0.3s ease-in-out;
  }

  .sidebar-open .page-content {
    transform: translateX(
      64px
    ); /* Adjust the value to match the sidebar width */
  }
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
}

.hamburger-menu span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: white;
  border-radius: 2px;
  transition: all 0.3s ease;
}

.hamburger-menu.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .social-links-container {
    gap: 20px; /* or any other value you prefer */
  }
}

/* Add this to your CSS file */
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
}

.float {
  animation: float 3s ease infinite;
}

.floating:hover {
  animation: float 3s ease infinite;
}

.hamburger-line {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.top-line.open {
  transform: translateY(8px) rotate(45deg);
}

.middle-line.open {
  opacity: 0;
}

.bottom-line.open {
  transform: translateY(-8px) rotate(-45deg);
}
